import { Postbox } from '../../api/postboxes/types';
import { trackEvent } from '../utils/telemetry';
import { Search } from './Search';
import { Slider } from './Slider';

interface HeaderProps {
  menuOpened: boolean;
  postboxes?: Postbox[];
  setMenuOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setFocusedPostbox: React.Dispatch<React.SetStateAction<Postbox | undefined>>;
}

export const Header = ({
  menuOpened,
  postboxes,
  setMenuOpened,
  setFocusedPostbox
}: HeaderProps) => {
  return (
    <header>
      <div className='menu'>
        <span
          className='material-symbols-outlined icon menuIcon shadow btn'
          onClick={() => {
            setMenuOpened(!menuOpened);
            trackEvent('sliderToggled');
          }}
        >
          menu
        </span>
        {postboxes && (
          <Search postboxes={postboxes} setFocusedPostbox={setFocusedPostbox} />
        )}
      </div>
      <Slider />
    </header>
  );
};
