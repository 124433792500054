import { useCookieConsentContext } from '@use-cookie-consent/react';
import { useEffect } from 'react';
import { gTagSafe } from '../utils/telemetry';

export const CookieBanner = () => {
  const { acceptAllCookies, declineAllCookies, consent } =
    useCookieConsentContext();

  useEffect(() => {
    if (consent.statistics === true) {
      gTagSafe('consent', 'update', {
        analytics_storage: 'granted'
      });
    }
  }, [consent]);

  if (consent.statistics !== undefined) {
    return null;
  }

  return (
    <div className='cookieConsentWrapper'>
      <div className='banner'>
        <h2>Can I have a cookie?</h2>
        <div className='description'>
          I'm kindly asking for a consent to store a cookie in your browser. We
          want to know which features users like and what post boxes are worth
          improving. (proximity one the map and picture of the post box). All
          informations are anonymous and not used for any other reason than
          statistics.
        </div>
        <button onClick={acceptAllCookies}>Accept</button>
        <button onClick={declineAllCookies} className='secondary'>
          Reject
        </button>
      </div>
    </div>
  );
};
