export const gTagSafe = (
  type: string,
  eventName: string,
  event: any = undefined
) => {
  if ((window as any).gtag && typeof (window as any).gtag === 'function') {
    (window as any).gtag(type, eventName, event);
  }
};

export const trackEvent = (eventName: string, event: any = undefined) => {
  gTagSafe('event', eventName, event);
};
