export function Slider() {
  return (
    <div className='slider shadow gradient'>
      <div className='title'>
        <img src='/marker.png' alt='Lux Postbox' width="30" height="35" />
        <span>
          Lux Postbox is a web-based, free to use, ad-free, beerware licensed
          product that I made for fun. (you can buy{' '}
          <a
            href='https://www.linkedin.com/in/jakubjanovic'
            target='_blank'
            rel='noreferrer'
          >
            me
          </a>{' '}
          a beer).
        </span>
      </div>
      <h3>
        Why? <b>Lux postbox</b>
      </h3>
      <ul>
        <li>
          <b>shows the postbox on the map</b> for easier orientation
        </li>
        <li>
          allows you to <b>search</b>{' '}
          <span className='material-symbols-outlined icon'>search</span> for the
          postbox by address
        </li>
        <li>
          uses <b>community for improvements</b>{' '}
          <span className='material-symbols-outlined icon'>favorite</span> of
          the position and (street)view orientation on the postbox itself
        </li>
        <li>
          provides a quick link to{' '}
          <b>
            share<span className='material-symbols-outlined icon'>share</span>{' '}
            the postbox
          </b>{' '}
          or <b>start the navigation</b>{' '}
          <span className='material-symbols-outlined icon'>near_me</span> to
          postbox
        </li>
      </ul>
      <h3>How to help?</h3>
      <p>
        Some post boxes are located differently from what is shown in the
        post.lu database. You can help improve the database for everyone by
        saving your corrections{' '}
        <span className='material-symbols-outlined icon '>save</span>. The
        street view may not display the picture right away, unless the posbox is
        verified{' '}
        <span className='material-symbols-outlined icon verified'>
          verified
        </span>
        . You may have to look around to find it, or mark it as not visible{' '}
        <span className='material-symbols-outlined icon'>visibility_off</span>.
        Please also mark the post box as invisible if the street view shows an
        indoor place. The post box may have been moved or removed.
      </p>
      <h3>Lastly</h3>
      <p>
        The post box locations are based on post.lu. This web page is not
        affiliated with the Luxembourgish post or any other organization.
      </p>
      <p>
        Lux postbox does not guarantee the accuracy of any post box or related
        information. It is updated and maintained by a community of people who
        want to keep the database current.
      </p>
      <p>
        Some of the content and some graphics were created with AI tools, thanks! In
        case you have any question or feedback to provide please send me a
        message to <span className='material-symbols-outlined icon'>email</span>{' '}
        <a href='mailto:moien@luxpostbox.com'>moien@luxpostbox.com</a>.
      </p>
      <div className='disclaimer'>© {new Date().getFullYear()} Lux Postbox</div>
    </div>
  );
}
