import { useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { Postbox } from '../../api/postboxes/types';
import { trackEvent } from '../utils/telemetry';

interface SearchProps {
  postboxes: Postbox[];
  setFocusedPostbox: React.Dispatch<React.SetStateAction<Postbox | undefined>>;
}
export function Search({ postboxes, setFocusedPostbox }: SearchProps) {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className='searchWrap'>
      <Autocomplete
        value={searchTerm}
        wrapperStyle={{ width: '100%' }}
        inputProps={{
          id: 'states-autocomplete',
          className: 'search shadow',
          placeholder: 'Search Lux Postbox...'
        }}
        items={postboxes}
        getItemValue={item => item.address} // must be a string
        shouldItemRender={(i, v) => {
          if (i.address && i.address.toLowerCase && v && v.toLowerCase) {
            return i.address.toLowerCase().indexOf(v.toLowerCase()) !== -1;
          }
          return true;
        }}
        sortItems={(i: Postbox) => i.id}
        onChange={(_e, v) => {
          setSearchTerm(v);
        }}
        onSelect={(_v, i) => {
          setFocusedPostbox(i);
          trackEvent('postboxOpened', { postboxId: i.id, source: 'search' });
          setSearchTerm(i.address);
        }}
        renderMenu={children => (
          <div className='searchMenu gradient shaddow'>{children}</div>
        )}
        renderItem={(item, isHighlighted) => (
          <div
            className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
            key={item.id}
          >
            {item.address}
          </div>
        )}
      />
      {searchTerm.trim().length > 0 ? (
        <span
          className='material-symbols-outlined icon btn'
          onClick={() => setSearchTerm('')}
        >
          clear
        </span>
      ) : (
        <span className='material-symbols-outlined icon'>search</span>
      )}
    </div>
  );
}
