/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { CookieConsentProvider } from '@use-cookie-consent/react';

import { Postbox } from '../api/postboxes/types';
import { Loading } from './components/Loading';
import { Error, ErrorProps } from './components/Error';
import { CookieBanner } from './components/CookieBanner';
import { trackEvent } from './utils/telemetry';
import { LuxPostboxMap } from './components/LuxPostboxMap';
import { Header } from './components/Header';
import { Navigation } from './components/Navigation';
import { InfoPanel } from './components/InfoPanel';

function App() {
  const [postboxes, setPostboxes] = React.useState<Postbox[]>();
  const [focusedPostbox, setFocusedPostbox] = React.useState<Postbox>();
  const [panoramaPostbox, setPanoramaPostbox] = React.useState<Postbox>();
  const [menuOpened, setMenuOpened] = React.useState<boolean>(false);
  const [myLocation, setMyLocation] = React.useState<GeolocationCoordinates>();
  const [panorama, setPanorama] = useState<google.maps.StreetViewPanorama>();
  const [error, setError] = useState<ErrorProps>();

  const focusOnPostboxFromUrl = () => {
    try {
      const urlParse = /(\d+)[^\d-]*$/.exec(window.location.pathname);
      if (postboxes && urlParse && urlParse[1] && parseInt(urlParse[1]) > -1) {
        setFocusedPostbox(
          postboxes.find(pb => pb.id === parseInt(urlParse[1])) || undefined
        );
        trackEvent('postboxOpened', {
          postboxId: parseInt(urlParse[1]),
          source: 'deeplink'
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    try {
      fetch(`${process.env.REACT_APP_POSTBOXES_HOST || ''}/api/postboxes`)
        .then(d => d.json())
        .then((d: Postbox[]) => setPostboxes(d))
        .catch(e => {
          trackEvent('error', e);
          setError({
            title: `Oops`,
            message: `Couldn't load the postboxes, please try again later`
          });
        });
    } catch (e) {
      trackEvent('error', e);
      setError({
        title: `Oops`,
        message: `Couldn't load the postboxes, please try again later`
      });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', event => {
      focusOnPostboxFromUrl();
    });
  }, [postboxes]);

  const render = (status: Status) => {
    if (!postboxes) {
      return <Loading />;
    } else {
      switch (status) {
        case Status.LOADING:
          return <Loading />;
        case Status.FAILURE:
          return <Error />;
        case Status.SUCCESS:
          return (
            <LuxPostboxMap
              myLocation={myLocation}
              postboxes={postboxes}
              setFocusedPostbox={setFocusedPostbox}
              focusedPostbox={focusedPostbox}
              panoramaPostbox={panoramaPostbox}
              setPanorama={setPanorama}
              focusOnPostboxFromUrl={focusOnPostboxFromUrl}
              panorama={panorama}
              setPanoramaPostbox={setPanoramaPostbox}
            />
          );
      }
    }
  };

  if (error) {
    return <Error />;
  }

  return (
    <CookieConsentProvider>
      <div
        className={`appWrap ${menuOpened ? 'menuOpened' : ''} ${
          panoramaPostbox ? 'panorama' : ''
        }`}
      >
        <Wrapper
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY || ''}
          render={render}
        />
        <Header
          menuOpened={menuOpened}
          setFocusedPostbox={setFocusedPostbox}
          postboxes={postboxes}
          setMenuOpened={setMenuOpened}
        />
        <Navigation setMyLocation={setMyLocation} />
        <InfoPanel
          focusedPostbox={focusedPostbox}
          panoramaPostbox={panoramaPostbox}
          panorama={panorama}
          setFocusedPostbox={setFocusedPostbox}
          setPanoramaPostbox={setPanoramaPostbox}
        />
      </div>
      <CookieBanner />
    </CookieConsentProvider>
  );
}

export default App;
