import { trackEvent } from '../utils/telemetry';

interface NavigationProps {
  setMyLocation: React.Dispatch<
    React.SetStateAction<GeolocationCoordinates | undefined>
  >;
}

export const Navigation = ({ setMyLocation }: NavigationProps) => {
  const getMyLocation = () => {
    trackEvent('requestLocation');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          trackEvent('locationGranted');
          setMyLocation(position.coords);
        },
        () => {
          console.error('cant get location');
        }
      );
    } else {
      console.error('position not supported by browser');
    }
  };
  
  return (
    <div className='navigation'>
      <span
        className='material-symbols-outlined mylocation shadow btn'
        onClick={getMyLocation}
      >
        my_location
      </span>
    </div>
  );
};
