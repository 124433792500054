export interface ErrorProps {
  title?: string;
  message?: string;
}

export function Error({
  title = 'Something went wrong',
  message = 'Please try again later'
}: ErrorProps) {
  return (
    <div className='fullMessage'>
      <h2>{title}</h2>
      {message && <p>{message}</p>}
    </div>
  );
}
