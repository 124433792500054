import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MarkerClusterer, Renderer } from '@googlemaps/markerclusterer';

import { Postbox } from '../../api/postboxes/types';
import { trackEvent } from '../utils/telemetry';
const markerIcon = './marker.png';
const markerCluster = './markerCluster.png';
const markerIconVerified = './markerVerified.png';
const markerNotVisible = './markerNotVisible.png';

interface LuxPostboxMapProps {
  postboxes: Postbox[];
  setFocusedPostbox: (c: Postbox | undefined) => void;
  setPanoramaPostbox: (c: Postbox | undefined) => void;
  focusedPostbox: Postbox | undefined;
  myLocation?: GeolocationCoordinates;
  panoramaPostbox?: Postbox;
  panorama: google.maps.StreetViewPanorama | undefined;
  focusOnPostboxFromUrl: () => void;
  setPanorama: React.Dispatch<
    React.SetStateAction<google.maps.StreetViewPanorama | undefined>
  >;
}

export function LuxPostboxMap({
  postboxes,
  setFocusedPostbox,
  focusedPostbox,
  myLocation,
  panoramaPostbox,
  setPanoramaPostbox,
  panorama,
  focusOnPostboxFromUrl,
  setPanorama
}: LuxPostboxMapProps) {
  const ref = useRef<HTMLInputElement>(null);
  const refPano = useRef<HTMLInputElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      trackEvent('closePostbox', { postboxid: focusedPostbox?.id });
      setPanoramaPostbox(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (refPano.current && map && panoramaPostbox?.lat && panoramaPostbox.lng) {
      if (!panorama) {
        const panoramaUnit = new google.maps.StreetViewPanorama(
          refPano?.current,
          {
            position: {
              lat: panoramaPostbox.lat,
              lng: panoramaPostbox.lng
            },
            pov: {
              heading: panoramaPostbox.pov?.heading || 34,
              pitch: panoramaPostbox.pov?.pitch || 10
            },
            zoom: panoramaPostbox.panoramaZoom || 1,
            addressControl: false,
            fullscreenControl: false,
            motionTrackingControlOptions: {
              position: google.maps.ControlPosition.TOP_RIGHT
            },
            panControlOptions: {
              position: google.maps.ControlPosition.TOP_RIGHT
            },
            zoomControlOptions: {
              position: google.maps.ControlPosition.TOP_RIGHT
            }
            // disableDefaultUI: true
          }
        );
        setPanorama(panoramaUnit);
        setPanorama(panoramaUnit);
        map.setStreetView(panoramaUnit);
      } else {
        panorama.setPosition({
          lat: panoramaPostbox.lat,
          lng: panoramaPostbox.lng
        });
        panorama.setPov({
          heading: panoramaPostbox.pov?.heading || 34,
          pitch: panoramaPostbox.pov?.pitch || 10
        });
        panorama.setZoom(panoramaPostbox.panoramaZoom || 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panoramaPostbox, map, setPanorama]);

  useEffect(() => {
    if (focusedPostbox) {
      const newCenter = {
        lat: focusedPostbox.lat,
        lng: focusedPostbox.lng
      };
      map?.panTo(newCenter);
      let el = document.querySelector(':focus');
      if (el) {
        // to get out of the search input and lose focus causing keyboard on
        (el as any).blur();
      }
      const currentZoom = map?.getZoom() || 0;
      if (currentZoom < 14) {
        map?.setZoom(15);
      }

      if (!window.location.href.endsWith(`-${focusedPostbox.id}`)) {
        const readableUrl = `${focusedPostbox.address
          .trim()
          .replace(/[^0-9a-z]/gi, '-')}-${focusedPostbox.id}`;
        window.history.pushState({}, readableUrl, readableUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedPostbox]);

  useEffect(() => {
    if (map && myLocation?.latitude && myLocation.longitude) {
      const position = {
        lat: myLocation?.latitude,
        lng: myLocation?.longitude
      };
      map?.panTo(position);
      map?.setZoom(15);
      new window.google.maps.Marker({
        position,
        map,
        label: 'Me'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myLocation]);

  useEffect(() => {
    const markers = postboxes.map(c => {
      const marker = new window.google.maps.Marker({
        position: {
          lng: c.lng || 0,
          lat: c.lat || 0
        },
        icon:
          c.visiblePanorama === true
            ? markerIconVerified
            : c.visiblePanorama === false
            ? markerNotVisible
            : markerIcon
      });
      marker.addListener('click', () => {
        setFocusedPostbox(undefined);
        setTimeout(() => {
          trackEvent('postboxOpened', { postboxId: c.id, source: 'mapMarker' });
          setFocusedPostbox(c);
        }, 200);
      });
      return marker;
    });

    const renderer: Renderer = {
      render: cluster =>
        new google.maps.Marker({
          label: {
            text: String(cluster.count),
            color: '#333333',
            fontSize: '13px'
          },
          position: cluster.position,
          // adjust zIndex to be above other markers
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + cluster.count,
          icon: markerCluster
        })
    };
    new MarkerClusterer({ map, markers, renderer });

    if (map) {
      google.maps.event.addListenerOnce(map, 'idle', () => {
        if (!mapLoaded) {
          setMapLoaded(true);
          focusOnPostboxFromUrl();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    if (ref.current) {
      setMap(
        new window.google.maps.Map(ref.current, {
          center: { lat: 49.718397, lng: 6.18159 },
          zoom: 9,
          clickableIcons: false,
          controlSize: 20,
          fullscreenControl: false,
          minZoom: 8,
          zoomControl: false,
          streetViewControl: false,
          mapTypeControlOptions: {
            position: google.maps.ControlPosition.BOTTOM_LEFT
          },
          mapId: '950ab5907b033647'
        })
      );
    }
  }, [ref]);
  return (
    <>
      <div ref={ref} id='map' />
      <div
        ref={refPano}
        id='pano'
        className={!!panoramaPostbox ? 'opened' : ''}
      />
    </>
  );
}
