import { useEffect, useState } from 'react';
import { Postbox, PostboxUpdate } from '../../api/postboxes/types';
import { trackEvent } from '../utils/telemetry';

interface InfoPanelProps {
  focusedPostbox: Postbox | undefined;
  panoramaPostbox: Postbox | undefined;
  panorama: google.maps.StreetViewPanorama | undefined;
  setFocusedPostbox: React.Dispatch<React.SetStateAction<Postbox | undefined>>;
  setPanoramaPostbox: React.Dispatch<React.SetStateAction<Postbox | undefined>>;
}

interface UpdateResult {
  type: 'success' | 'failure';
  message: string;
}

export const InfoPanel = ({
  focusedPostbox,
  panoramaPostbox,
  panorama,
  setFocusedPostbox,
  setPanoramaPostbox
}: InfoPanelProps) => {
  const [improving, setImproving] = useState<boolean>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [updateResult, setUpdateResult] = useState<UpdateResult>();

  useEffect(() => {
    if (
      focusedPostbox &&
      panoramaPostbox &&
      focusedPostbox !== panoramaPostbox
    ) {
      setPanoramaPostbox(focusedPostbox);
    }
    setImproving(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedPostbox]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sendUpdate = (type: 'edit' | 'delete', visible = true) => {
    if (panorama && focusedPostbox && panorama.getPosition) {
      const panoramaPosition = panorama.getPosition();
      const updatedInfo: PostboxUpdate = {
        postboxid: focusedPostbox.id,
        updateType: type,
        updateTimestamp: Date.now(),
        details: {
          city: focusedPostbox.city,
          type: focusedPostbox.type,
          street: focusedPostbox.street,
          pickupTime: focusedPostbox.pickupTime,
          address: focusedPostbox.address,
          detail: focusedPostbox.detail
        },
        pov: panorama.getPov(),
        panoramaZoom: panorama.getZoom() || 1,
        visiblePanorama: visible,
        position: panoramaPosition
          ? {
              lat: panoramaPosition?.lat(),
              lng: panoramaPosition?.lng()
            }
          : undefined
      };
      setUpdating(true);
      setUpdateResult(undefined);

      fetch(`${process.env.REACT_APP_POSTBOXES_HOST || ''}/api/postboxes`, {
        method: 'POST',
        body: JSON.stringify(updatedInfo)
      })
        .then(d => d.json())
        .then(d => {
          setUpdateResult({
            type: 'success',
            message: 'Thank you very much, we will review your entry'
          });
          window.setTimeout(() => {
            setUpdateResult(undefined);
          }, 5000);
        })
        .finally(() => {
          setUpdating(false);
        })
        .catch(() => {
          console.error(`Postbox update failed: ${type}`, focusedPostbox);
        });
    }
  };

  const shareButton = () => {
    const shareContent = focusedPostbox
      ? {
          title: `Lux Postbox: ${focusedPostbox.address}`,
          text: `Find the details of this postbox on Lux Postbox. Navigate, share, contribute.`,
          url: window.location.href
        }
      : undefined;

    window.navigator.canShare && window.navigator.canShare(shareContent) && (
      <span
        className='button btn'
        onClick={async () => {
          try {
            await navigator.share(shareContent);
            trackEvent('postboxShared', { postboxid: focusedPostbox?.id });
          } catch (err) {
            console.error('could not share the postbox', err);
          }
        }}
      >
        <span className='material-symbols-outlined icon'>share</span>
        Share postbox
      </span>
    );
  };

  const notVisible = focusedPostbox?.visiblePanorama === false;

  return (
    <div className={`info gradient shadow ${focusedPostbox ? 'open' : ''}`}>
      <span
        className='material-symbols-outlined close btn'
        onClick={() => {
          trackEvent('closePostbox', { postboxid: focusedPostbox?.id });
          setFocusedPostbox(undefined);
          setPanoramaPostbox(undefined);
        }}
      >
        close
      </span>
      <div className='address'>
        <div className='text bold'>
          {focusedPostbox?.visiblePanorama === true && (
            <span className='verified material-symbols-outlined'>verified</span>
          )}
          {focusedPostbox?.address}
        </div>
        <div className='detail'>{focusedPostbox?.detail}</div>
      </div>
      {!improving && !updateResult && (
        <div className='pickupTime'>
          Collection time {focusedPostbox?.pickupTime}
          <span className='disclaimer'>in working days</span>
        </div>
      )}
      {updateResult && (
        <div className={`updateResult ${updateResult.type}`}>
          {updateResult.message}
        </div>
      )}
      {improving && !updateResult && (
        <div className='improving'>
          <b>Thanks for helping LuxPostbox.</b> Send us your updates. We will
          check and apply them after our confirmation.
          {focusedPostbox?.visiblePanorama === true && (
            <p>
              Making changes to this postbox might take longer than expected,
              because it is already verified.
            </p>
          )}
          <ul>
            <li
              className={`${
                focusedPostbox?.visiblePanorama === false ? 'disabled' : ''
              }`}
            >
              <span className='material-symbols-outlined icon'>save</span>
              To save the post box location, adjust the viewport to make it
              visible and click “save”
            </li>
            <li
              className={`${
                focusedPostbox?.visiblePanorama === false ? 'disabled' : ''
              }`}
            >
              <span className='material-symbols-outlined icon'>
                visibility_off
              </span>
              If the viewport shows no street or an indoor place, please mark it
              as not visible.
            </li>
            <li>
              <span className='material-symbols-outlined icon'>delete</span>
              If the post box is gone, please delete it.
            </li>
          </ul>
        </div>
      )}
      <div className={`loading ${updating ? 'shown' : ''}`}></div>

      <div className='buttons bold'>
        <div className='block'>
          {!improving && (
            <>
              <span
                className={`button btn ${panoramaPostbox ? 'active' : ''} ${
                  notVisible ? 'disabled' : ''
                }`}
                onClick={() => {
                  if (focusedPostbox?.visiblePanorama !== false) {
                    trackEvent('panoramaOpened', {
                      postboxid: focusedPostbox?.id
                    });
                    setPanoramaPostbox(
                      !!panoramaPostbox ? undefined : focusedPostbox
                    );
                  }
                }}
              >
                <span className='material-symbols-outlined icon'>
                  visibility
                </span>
                {focusedPostbox?.visiblePanorama === true
                  ? 'See postbox'
                  : focusedPostbox?.visiblePanorama === false
                  ? 'Not visible'
                  : 'Find postbox'}
              </span>
              <a
                className='button'
                href={
                  !focusedPostbox?.visiblePanorama
                    ? `https://www.google.com/maps?daddr=${focusedPostbox?.address},Luxembourg`
                    : `https://www.google.com/maps/dir/?api=1&destination=${focusedPostbox.lat},${focusedPostbox.lng}`
                }
                rel='noreferrer'
                target='_blank'
              >
                <span className='material-symbols-outlined icon'>near_me</span>
                Open navigation
              </a>
              {shareButton()}
            </>
          )}
        </div>
        <div className='block'>
          {!improving && (
            <span
              className='button btn'
              onClick={() => {
                trackEvent('improveOpened', { postboxid: focusedPostbox?.id });
                setImproving(true);
                if (
                  !panoramaPostbox &&
                  focusedPostbox &&
                  focusedPostbox.visiblePanorama !== false
                ) {
                  setPanoramaPostbox(focusedPostbox);
                }
              }}
            >
              <span className='material-symbols-outlined icon'>favorite</span>
              Improve postbox
            </span>
          )}
          {improving && (
            <>
              <span
                className='button btn'
                onClick={() => {
                  trackEvent('improveClosed', {
                    postboxid: focusedPostbox?.id
                  });
                  setImproving(false);
                }}
              >
                <span className='material-symbols-outlined icon'>cancel</span>
                Back
              </span>

              <span
                className={`button btn ${
                  notVisible || updateResult || updating ? 'disabled' : ''
                }`}
                onClick={() => {
                  if (
                    focusedPostbox?.visiblePanorama !== false ||
                    updating ||
                    !updateResult
                  ) {
                    trackEvent('improveSaved', {
                      postboxid: focusedPostbox?.id
                    });
                    sendUpdate('edit');
                  }
                }}
              >
                <span className='material-symbols-outlined icon'>save</span>
                Save position
              </span>

              <span
                className={`button btn ${
                  focusedPostbox?.visiblePanorama === false ||
                  updateResult ||
                  updating
                    ? 'disabled'
                    : ''
                }`}
                onClick={() => {
                  if (!updateResult && !updating) {
                    trackEvent('improveInvisible', {
                      postboxid: focusedPostbox?.id
                    });
                    sendUpdate('edit', false);
                  }
                }}
              >
                <span className='material-symbols-outlined icon'>
                  visibility_off
                </span>
                Not visible
              </span>
              <span
                className={`button btn ${
                  updating || updateResult ? 'disabled' : ''
                }`}
                style={{ color: '#cc0000' }}
                onClick={() => {
                  if (!updateResult && !updating) {
                    trackEvent('improveDelete', {
                      postboxid: focusedPostbox?.id
                    });
                    sendUpdate('delete', false);
                  }
                }}
              >
                <span className='material-symbols-outlined icon'>delete</span>
                Remove postbox
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
